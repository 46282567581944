import { ReactNode } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import { useShiftsListStore } from '@libs/store/shifts';
import { Shift } from '@libs/models/shifts';
import { ShiftTypeInline } from '@organisms/dataDisplay';
import { InfoBlocks, ShiftRecurrenceChip } from '@atoms/dataDisplay';
import { Flex, PrimaryPaper } from '@atoms/layout';
import { ShiftAssigneeButton } from '../shiftAssigneeButton';

export function GeneralInfo() {
  const { currentShift } = useShiftsListStore();

  if (!currentShift) {
    return null;
  }

  return (
    <>
      <Typography variant="subtitle1">
        <FormattedMessage id="shifts.fields.assignee" />
      </Typography>

      <PrimaryPaper sx={{ mt: 1 }}>
        <Panel>
          <ShiftAssigneeButton shift={currentShift} fullWidth />
        </Panel>
      </PrimaryPaper>

      <ShiftDetailsInfoBlock shift={currentShift} />

      <LocationDetailsInfoBlock shift={currentShift} />

      <SchedulerInfoBlock
        title={<FormattedMessage id="shifts.mainScheduler" />}
        scheduler={currentShift.mainScheduler}
      />

      <SchedulerInfoBlock
        title={<FormattedMessage id="shifts.afterHoursScheduler" />}
        scheduler={currentShift.afterHoursScheduler}
      />
    </>
  );
}

function ShiftDetailsInfoBlock({ shift }: { shift: Shift }) {
  return (
    <InfoBlocks
      title={<FormattedMessage id="shifts.shiftDetails" />}
      items={[
        {
          key: 'recurrence',
          title: <FormattedMessage id="shifts.fields.recurrence" />,
          value: (
            <ShiftRecurrenceChip
              variant="outlined"
              type={shift.recurrence}
              label={<FormattedMessage id={`shifts.recurrence.${shift.recurrence}`} />}
              size="small"
            />
          ),
        },
        {
          key: 'shiftType',
          title: <FormattedMessage id="shifts.fields.shiftType" />,
          value: <ShiftTypeInline startTime={dayjs(shift.shiftStart)} length={shift.shiftLength} />,
        },
        {
          key: 'qualification',
          title: <FormattedMessage id="talents.qualification" />,
          value: (
            <Flex gap={1} flexWrap={'wrap'}>
              {shift.qualifications.split(', ').map((qualification) => (
                <Chip key={qualification} label={qualification} size="small" />
              ))}
            </Flex>
          ),
        },
        {
          key: 'shiftDate',
          title: <FormattedMessage id="shifts.fields.shiftDate" />,
          value: <FormattedDate value={shift.shiftStart} />,
        },
        {
          key: 'specialty',
          title: <FormattedMessage id="shifts.fields.specialty" />,
          value: <Typography variant="body2">{shift.specialty}</Typography>,
        },
      ]}
    />
  );
}

function LocationDetailsInfoBlock({ shift }: { shift: Shift }) {
  return (
    <>
      <InfoBlocks
        title={<FormattedMessage id="shifts.shiftDetails" />}
        items={[
          {
            key: 'locationName',
            title: <FormattedMessage id="shifts.fields.locationName" />,
            value: <Typography>{shift.location.name}</Typography>,
          },
          {
            key: 'address',
            title: <FormattedMessage id="shifts.fields.address" />,
            value: (
              <Typography>{[shift.location.address1, shift.location.address2].filter(Boolean).join(' ')}</Typography>
            ),
          },
          {
            key: 'department',
            title: <FormattedMessage id="shifts.fields.department" />,
            value: <Typography>{shift.locationDetails?.department || '-'}</Typography>,
          },
          {
            key: 'subDepartment',
            title: <FormattedMessage id="shifts.fields.subDepartment" />,
            value: <Typography>{shift.locationDetails?.subDepartment || '-'}</Typography>,
          },
          {
            key: 'primaryPhoneNumber',
            title: <FormattedMessage id="fields.primaryPhoneNumber" />,
            value: <Typography>{shift.locationDetails?.primaryPhoneNumber || '-'}</Typography>,
          },
          {
            key: 'secondaryPhoneNumber',
            title: <FormattedMessage id="fields.secondaryPhoneNumber" />,
            value: <Typography>{shift.locationDetails?.secondaryPhoneNumber || '-'}</Typography>,
          },
        ]}
      />
    </>
  );
}

function SchedulerInfoBlock({
  scheduler,
  title,
}: {
  scheduler?: {
    name: string;
    jobTitle: string;
    primaryPhoneNumber: string;
    email: string;
  };
  title: ReactNode;
}) {
  return (
    <>
      <InfoBlocks
        title={title}
        items={[
          {
            key: 'name',
            title: <FormattedMessage id="common.name" />,
            value: <Typography>{scheduler?.name ?? '-'}</Typography>,
          },
          {
            key: 'jobTitle',
            title: <FormattedMessage id="fields.jobTitle" />,
            value: <Typography>{scheduler?.jobTitle ?? '-'}</Typography>,
          },
          {
            key: 'primaryPhoneNumber',
            title: <FormattedMessage id="fields.primaryPhoneNumber" />,
            value: <Typography>{scheduler?.primaryPhoneNumber ?? '-'}</Typography>,
          },
          {
            key: 'email',
            title: <FormattedMessage id="fields.email" />,
            value: <Typography>{scheduler?.email ?? '-'}</Typography>,
          },
        ]}
      />
    </>
  );
}

const Panel = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1.5),
}));
