import { create } from 'zustand';
import { ShiftNote } from '@libs/models/shifts/shiftNote';

type State = {
  notes: ShiftNote[];
  additionalNotes: ShiftNote[];
  loading: boolean;
  shiftId: string | null;
  loadNotes: (shiftId: string) => void;
};

export const useShiftNotesStore = create<State>()((set, get) => ({
  notes: [],
  additionalNotes: [],
  loading: false,
  shiftId: null,
  loadNotes: async (newId: string) => {
    const { shiftId } = get();
    const sameId = shiftId === newId;
    // Suppress loading state if the shiftId is the same
    set({ loading: !sameId });
    if (!sameId) {
      // Clear notes if the shiftId is different
      set({ notes: [] });
    }

    await new Promise((resolve) => setTimeout(resolve, 1000));

    set({ notes: notesMock, additionalNotes: additionalNotesMock, loading: false, shiftId: newId });
  },
}));

const notesMock = [
  {
    id: 'Check-in',
    title: 'Check-in',
    body: 'Please arrive 10 minutes early and check in at the front desk',
  },
  {
    id: 'Timekeeping',
    title: 'Timekeeping',
    body: "Use the facility's timekeeping system upon arrival and departure",
  },
  {
    id: 'Parking',
    title: 'Parking',
    body: 'Parking is available in Lot B. Enter through the South entrance, and parking permits are not required',
  },
  {
    id: 'Dress code',
    title: 'Dress code',
    body: 'Wear your scrubs or professional attire. Closed-toe shoes are mandatory',
  },
];
const additionalNotesMock = [
  {
    id: 'Facility note',
    title: 'Facility note',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  },
];
