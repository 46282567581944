import Chip from '@mui/material/Chip';
import { alpha, styled } from '@mui/material/styles';

export const LightChip = styled(Chip)(({ theme, color }) => {
  if (color && color !== 'default') {
    return {
      backgroundColor: alpha(theme.palette[color].main, 0.04),
      color: theme.palette[color].main,
    };
  }
});
