import React from 'react';
import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { Providers } from './Providers.tsx';
import { App } from './app.tsx';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './styles.css';
import { initTime } from '@libs/intl/time.ts';
import { Analytics } from '@libs/analytics/amplitude.ts';
import { FeatureFlags } from '@libs/analytics/featureFlags.ts';
import { FEATURE_FLAG_DEPLOYMENT_KEY } from '@libs/config';

initTime();
Analytics.track('App:open');
FeatureFlags.init(FEATURE_FLAG_DEPLOYMENT_KEY)
  .then(() => {
    console.log('Feature flags init');
  })
  .catch(console.error);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Providers>
      <CssBaseline />
      <App />
    </Providers>
  </React.StrictMode>,
);
