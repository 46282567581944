import Dialog, { DialogProps } from '@mui/material/Dialog';
import { paperClasses } from '@mui/material/Paper';

export type PrimaryDialogProps = DialogProps;
export function PrimaryDialog({ sx, ...props }: DialogProps) {
  return (
    <Dialog
      sx={(theme) => ({
        [`& .${paperClasses.root}`]: {
          bgcolor: `color-mix(in srgb, #fff 100%, ${theme.palette.primary.main} 8%)`,
        },
        ...sx,
      })}
      {...props}
    />
  );
}
