import { Location } from '@libs/models/locations';
import { Filter } from '@libs/models/common';
import { filterToQueryParams } from '../utils/filterToQueryParams';
import { request } from '../request';

export type GetLocationsParams = {
  orderBy?: { field: string; sort: string }[];
  search?: string;
  page?: number;
  size?: number;
  filters?: {
    field: string;
    value: Filter['value'];
  }[];
};
export function getLocations(params?: GetLocationsParams) {
  const filters = filterToQueryParams(params?.filters ?? []);
  return request.get<LocationResponse[]>('/v2/settings/locations', {
    params: {
      pageNumber: params?.page,
      pageSize: params?.size,
      orderBy_in: params?.orderBy?.map((p) => {
        return `${p.field}_${p.sort}`;
      }),
      search: params?.search,
      ...filters,
    },
  });
}
type LocationResponse = Location;
