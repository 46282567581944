import { FormattedMessage } from 'react-intl';
import { Typography, useTheme } from '@mui/material';
import { Box, Flex } from '@atoms/layout';
import { Button } from '@atoms/buttons';

type LicenseInfoProps = {
  trialDaysLeft?: number;
};
export function LicenseInfo({ trialDaysLeft = 0 }: LicenseInfoProps) {
  const theme = useTheme();
  return (
    <Flex alignCenter>
      <Typography variant="body2" color={theme.palette.warning.main} noWrap>
        <FormattedMessage id="license.trial.daysLeft" values={{ days: trialDaysLeft }} />
      </Typography>
      <Box ml={1}>
        <Button size="small" variant="contained" color="warning" rounded sx={{ px: 2 }}>
          <FormattedMessage id="license.trial.upgradeBtnLabel" />
        </Button>
      </Box>
    </Flex>
  );
}
