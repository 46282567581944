import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { alpha, ButtonBase, buttonBaseClasses, Divider, Stack, styled } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import { useOnboardingStore } from '@libs/store/onboarding/onboardingStore';
import { Box, Flex } from '@atoms/layout';
import { LightChip } from '@atoms/dataDisplay';
import { FaIcon, RotatingIcon } from '@atoms/icons';
import { CircularProgress } from '@molecules/feedback';
import { UndecoratedLink } from '@molecules/index';

export const OnboardingProgress = () => {
  const theme = useTheme();
  const { items, completedSteps } = useOnboardingStore();
  const progress = calculateProgress(completedSteps);
  const [expanded, setExpanded] = useState(false);
  const completedStepsCount = Object.values(completedSteps).filter(Boolean).length;

  return (
    <ClickAwayListener onClickAway={() => setExpanded(false)}>
      <Box sx={{ position: 'relative' }}>
        <Paper sx={{ borderRadius: '20px', position: 'absolute', zIndex: 1 }} elevation={expanded ? 4 : 0}>
          <Collapse in={expanded} collapsedSize={40}>
            <HorizontalCollapse expanded={expanded}>
              <HeaderContainer
                expanded={expanded}
                alignCenter
                onClick={() => setExpanded(!expanded)}
                justifyContent="space-between"
                tabIndex={0}
                role="button"
                aria-expanded={expanded}
                aria-controls="onboarding-header-list"
              >
                <Flex alignCenter>
                  <CircularProgress progress={progress} />
                  <Box ml={1}>
                    <Typography variant="body2" fontWeight={500}>
                      <FormattedMessage id="onboarding.progress.gettingStarted" />
                    </Typography>
                  </Box>
                </Flex>
                <Box ml={1} mr={1.5}>
                  <RotatingIcon
                    name="chevron-right"
                    variant="light"
                    color={theme.palette.text.primary}
                    activeAngle={-90}
                    inactiveAngle={90}
                    active={expanded}
                  />
                </Box>
              </HeaderContainer>
              <Divider />
              <Flex p={2} justifyContent="space-between">
                <Typography variant="subtitle1" fontWeight="500">
                  <FormattedMessage id="onboarding.progress.menuHeader" />
                </Typography>
                <LightChip label={`${completedStepsCount}/${items.length}`} size="small" color="primary" />
              </Flex>
              <Stack gap={2} id="onboarding-header-list" mb={2} width="400px">
                {items.map((item) => (
                  <OnboardingItem key={item.id} expanded={expanded} link={item.actionLink} {...item} />
                ))}
              </Stack>
            </HorizontalCollapse>
          </Collapse>
        </Paper>
      </Box>
    </ClickAwayListener>
  );
};

type OnboardingItemProps = {
  id: string;
  icon: React.ReactNode;
  titleKey: string;
  subtitleKey: string;
  expanded: boolean;
  link: string;
};
function OnboardingItem({ icon, titleKey, subtitleKey, expanded, link }: OnboardingItemProps) {
  const theme = useTheme();

  return (
    <UndecoratedLink to={link} tabIndex={-1} sx={{ margin: theme.spacing(0, 2) }}>
      <OnboardingItemButton disabled={!expanded} sx={{ width: '100%' }}>
        <Flex justifyContent="space-between" px={1} alignCenter width="100%" textAlign="left">
          <Flex alignCenter mr={4}>
            <Box fontSize={25} width="32px">
              {icon}
            </Box>
            <Flex column ml={1.5}>
              <Typography variant="subtitle2">
                <FormattedMessage id={titleKey} />
              </Typography>
              <Typography variant="body2">
                <FormattedMessage id={subtitleKey} />
              </Typography>
            </Flex>
          </Flex>
          <ChevronButton alignCenter justifyCenter className="chevronButton">
            <FaIcon name="chevron-right" variant="light" />
          </ChevronButton>
        </Flex>
      </OnboardingItemButton>
    </UndecoratedLink>
  );
}

const HorizontalCollapse = styled(Box, {
  shouldForwardProp: (prop: string) => !['expanded'].includes(prop),
})<{ expanded: boolean }>`
  width: ${({ expanded }) => (expanded ? '400px' : '180px')};
  transition: width 0.3s;
`;

const HeaderContainer = styled(Flex, {
  shouldForwardProp: (prop: string) => !['expanded'].includes(prop),
})<{ expanded: boolean }>`
  height: ${({ expanded }) => (expanded ? '54px' : '40px')};
  width: 100%;
  align-items: center;
  padding: ${({ expanded, theme }) => (expanded ? theme.spacing(1, 2) : theme.spacing(0.5))};
  cursor: pointer;
  transition:
    padding 0.3s,
    height 0.3s;
  outline: none;
  border: 1px solid transparent;
  border-radius: 20px;
  &:focus-visible {
    border: 1px solid ${({ theme }) => alpha(theme.palette.common.black, 0.23)};
  }
`;

const ChevronButton = styled(Flex)`
  width: ${({ theme }) => theme.spacing(4)};
  height: ${({ theme }) => theme.spacing(4)};
  border-radius: 50%;
`;

const OnboardingItemButton = styled(ButtonBase)`
  border-radius: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(0.75, 0)};
  box-sizing: border-box;
  border: 1px solid transparent;
  &:hover,
  &.${buttonBaseClasses.focusVisible} {
    border: 1px solid ${({ theme }) => alpha(theme.palette.common.black, 0.23)};
  }
  &:hover .chevronButton {
    background-color: ${({ theme }) => alpha(theme.palette.common.black, 0.04)};
  }
`;

function calculateProgress(completedSteps: Record<string, boolean>) {
  const totalSteps = Object.keys(completedSteps).length;
  const completedStepsCount = Object.values(completedSteps).filter(Boolean).length;
  return Math.round((completedStepsCount / totalSteps) * 100);
}
