import { forwardRef, ReactNode } from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';
import { Dayjs } from 'dayjs';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Timeline from '@mui/lab/Timeline';
import { DuotoneIcon } from '@atoms/icons';
import { Flex } from '@atoms/layout';

type LogTimelineProps = {
  items: {
    date: Dayjs;
    content: ReactNode;
  }[];
};
export const LogTimeline = forwardRef<HTMLUListElement, LogTimelineProps>(function LogTimeline({ items }, ref) {
  return (
    <LeftTimeline ref={ref}>
      {items.map((item, index) => (
        <TimelineItem sx={{ minHeight: '62px' }} key={index}>
          <Separator showConnector={index < items.length - 1} />
          <TimelineContent sx={{ pt: 0, pl: 1.5, pb: 0 }}>
            <Flex justifyContent="space-between">
              <Typography variant="body2">{item.content}</Typography>
              <LogTimestamp date={item.date} />
            </Flex>
          </TimelineContent>
        </TimelineItem>
      ))}
    </LeftTimeline>
  );
});

function LogTimestamp({ date }: { date: Dayjs }) {
  return (
    <Typography variant="caption">
      <FormattedDate value={date.toDate()} month="short" year="numeric" day="numeric" />
      {', '}
      <FormattedTime value={date.toDate()} hour12 />
    </Typography>
  );
}

function Separator({ showConnector }: { showConnector: boolean }) {
  return (
    <TimelineSeparator>
      <TimelineDotIcon>
        <DuotoneIcon name="circle-dot" active />
      </TimelineDotIcon>
      {showConnector && <PrimaryTimelineConnector />}
    </TimelineSeparator>
  );
}

const LeftTimeline = styled(Timeline)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  marginBlockStart: theme.spacing(1.5),
  [`& .${timelineItemClasses.root}:before`]: {
    flex: 0,
    padding: 0,
  },
}));

const TimelineDotIcon = styled(TimelineDot)(({ theme }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  padding: 0,
  borderWidth: 0,
  fontSize: theme.spacing(1.5),
  margin: theme.spacing(0.5, 0),
}));

const PrimaryTimelineConnector = styled(TimelineConnector)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: 100,
}));
