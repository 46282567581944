import 'react-international-phone/style.css';
import { forwardRef, useId } from 'react';
import { BaseTextFieldProps, Button, InputAdornment, MenuItem, Select, Typography } from '@mui/material';
import { CountryIso2, defaultCountries, FlagImage, parseCountry, usePhoneInput } from 'react-international-phone';
import { TextField } from './textField';

export interface PhoneNumberProps extends BaseTextFieldProps {
  value?: string | null;
  onChange?: (phone: string) => void;
}

export const PhoneNumber = forwardRef<HTMLDivElement, PhoneNumberProps>(function PhoneNumber(
  { value, onChange, ...restProps },
  ref,
) {
  const id = useId();
  const { disabled } = restProps;
  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
    defaultCountry: 'us',
    value: value ?? '',
    countries: defaultCountries,
    onChange: (data) => {
      onChange?.(data.inputValue);
    },
    disableDialCodePrefill: true,
  });

  return (
    <TextField
      id={id}
      value={inputValue}
      onChange={handlePhoneValueChange}
      type="tel"
      inputRef={inputRef}
      ref={ref}
      InputProps={{
        sx: {
          borderRadius: '28px',
          overflow: 'hidden',
        },
        startAdornment: (
          <InputAdornment position="start" style={{ marginRight: '2px', marginLeft: '-8px' }}>
            {/* Button is for visualizing `focus` on select without border. */}
            {/* `tabIndex={-1}` to disable focus on button itself. Otherwise there will be to focused element. */}
            <Button tabIndex={-1} disabled={disabled}>
              <Select
                MenuProps={{
                  style: {
                    height: '300px',
                    width: '360px',
                    top: '10px',
                    left: '-34px',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
                sx={{
                  width: 'max-content',
                  // Remove default outline
                  fieldset: {
                    display: 'none',
                  },
                  // Update default spacing
                  '.MuiSelect-select': {
                    padding: '8px',
                    paddingRight: '24px !important',
                  },
                  svg: {
                    right: 0,
                  },
                }}
                value={country.iso2}
                onChange={(e) => setCountry(e.target.value as CountryIso2)}
                renderValue={(value) => (
                  <FlagImage iso2={value} style={{ display: 'flex', opacity: disabled ? 0.5 : 1 }} />
                )}
                disabled={disabled}
              >
                {defaultCountries.map((c) => {
                  const country = parseCountry(c);
                  return (
                    <MenuItem key={country.iso2} value={country.iso2}>
                      <FlagImage iso2={country.iso2} style={{ marginRight: '8px' }} />
                      <Typography marginRight="8px">{country.name}</Typography>
                      <Typography color="gray">+{country.dialCode}</Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </Button>
          </InputAdornment>
        ),
      }}
      {...restProps}
    />
  );
});
