import { PropsWithChildren } from 'react';
import { IntlProvider as Provider } from 'react-intl';
import msges from './messages/en';

export function IntlProvider({ children }: PropsWithChildren) {
  return (
    <Provider messages={msges} locale="en" defaultLocale="en">
      {children}
    </Provider>
  );
}
