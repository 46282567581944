import { forwardRef, ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Box, PrimaryPaper } from '@atoms/layout';

export type InfoPanelProps = {
  items: { title: ReactNode; value?: ReactNode; key: string }[];
  title?: ReactNode;
  columns?: number;
};

export const InfoBlocks = forwardRef<HTMLDivElement, InfoPanelProps>(function InfoBlocks(
  { title, items, columns = 2 },
  ref,
) {
  return (
    <Box ref={ref}>
      {title && (
        <Typography variant="subtitle1" sx={{ mt: 2 }}>
          {title}
        </Typography>
      )}

      <PrimaryPaper sx={{ mt: title ? 1 : 0 }}>
        <Grid container rowSpacing={2} columnSpacing={2}>
          {items.map((item) => (
            <Grid key={item.key} xs={12} sm={12 / columns}>
              <Panel sx={{ height: '100%' }}>
                <Typography variant="subtitle2">{item.title}</Typography>
                <Box mt={1}>{item.value ?? '-'}</Box>
              </Panel>
            </Grid>
          ))}
        </Grid>
      </PrimaryPaper>
    </Box>
  );
});

const Panel = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1.5),
}));
