import { create } from 'zustand';
import { parse } from '@libs/utils/json';
import { onboardingItems } from './onboardingItems';

type InitialState = {
  items: typeof onboardingItems;
  showWidget: boolean;
  completedSteps: Record<string, boolean>;
  hideWidget: () => void;
  setCompletedStep: (step: string) => void;
};

export const useOnboardingStore = create<InitialState>()((set) => ({
  completedSteps: restoreCompletedSteps(),
  showWidget: localStorage.getItem('hideOnboardingWidget') !== 'true',
  items: onboardingItems,
  hideWidget: () => {
    localStorage.setItem('hideOnboardingWidget', 'true');
    set({ showWidget: false });
  },
  setCompletedStep: (step) => {
    set((state) => {
      const newCompletedSteps = { ...state.completedSteps, [step]: true };
      localStorage.setItem('onboarding.completedSteps', JSON.stringify(newCompletedSteps));
      return {
        completedSteps: newCompletedSteps,
      };
    });
  },
}));

function restoreCompletedSteps(): Record<string, boolean> {
  const savedValue = parse<Record<string, boolean>>(localStorage.getItem('onboarding.completedSteps') || '{}');

  return {
    organization: savedValue['organization'] ?? false,
    branding: savedValue['branding'] ?? false,
    users: savedValue['users'] ?? false,
    clients: savedValue['clients'] ?? false,
    talents: savedValue['talents'] ?? false,
    shifts: savedValue['shifts'] ?? false,
  };
}
