import { ReactNode } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import { Select } from '@atoms/inputs';

type SelectProps = {
  value?: string;
  fullWidth?: boolean;
  onChange?: (event: SelectChangeEvent<string>, child: React.ReactNode) => void;
  options?: { value: string; label: ReactNode; key: string }[];
  multiple?: boolean;
};

export function HeaderSelect(props: SelectProps) {
  return <Select rounded size="small" {...props} />;
}
