import Step, { StepProps } from '@mui/material/Step';
import { StepperContextType, useStepperContext } from '@mui/material/Stepper';
import { alpha, useTheme } from '@mui/material/styles';

export function MenuStepperStep(props: StepProps) {
  const { index } = props;
  const theme = useTheme();
  const stepperCtx = useStepperContext() as Partial<StepperContextType>;
  const activeStep = stepperCtx.activeStep;
  const isActive = activeStep === index;

  return (
    <Step
      sx={{
        backgroundColor: isActive ? alpha(theme.palette.primary.main, 0.08) : undefined,
        px: 2,
        py: 0.25,
        borderRadius: 3,
        cursor: 'pointer',
      }}
      {...props}
    />
  );
}
