import { forwardRef, ReactNode } from 'react';
import MuiAutocomplete, { AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete';
import { TextField, TextFieldProps } from './textField';

export type AutocompleteProps<O> = Omit<MuiAutocompleteProps<O, false, false, false>, 'renderInput' | 'value'> & {
  value?: string;
  required?: boolean;
  label?: ReactNode;
  textFieldProps?: TextFieldProps;
  error?: boolean;
  helperText?: ReactNode;
  placeholder?: string;
};
function AutocompleteInner<O extends AutocompleteOption>(
  { value, label, textFieldProps, error, helperText, placeholder, ...props }: AutocompleteProps<O>,
  ref: React.Ref<HTMLInputElement>,
) {
  const { options, required } = props;
  const internalValue = options.find((o) => o.value === value);

  return (
    <MuiAutocomplete
      {...props}
      value={internalValue ?? null}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            {...textFieldProps}
            label={label}
            inputRef={ref}
            placeholder={placeholder}
            error={error}
            helperText={helperText}
            required={!!label && required}
          />
        );
      }}
    />
  );
}

// Due to impossibility to pass generic while using the forwardRef, we need to cast the component to the correct type
// https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref
export const Autocomplete = forwardRef(AutocompleteInner) as typeof AutocompleteInner;

export type AutocompleteOption = {
  value: string;
  label: string;
};
