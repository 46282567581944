import { create } from 'zustand';
import { login, resendOtp, submitOtp, getUserInfo } from '@libs/api/auth';
import { updateToken } from '@libs/api/request';
import { parseError } from '@libs/api/errors';
import { SystemUser } from '@libs/models/common';

type State = {
  loading: boolean;
  userData: SystemUser | null;
  userDataLoading: boolean;

  fetchUserData: () => Promise<void>;
  login: (email: string) => Promise<void>;
  flushToken: () => void;
  submitOtp: (email: string, otp: string) => Promise<void>;
  resendOtp: (email: string) => Promise<void>;
  isAuthenticated: () => boolean;
};

export const useAuthStore = create<State>()((set) => ({
  loading: false,
  userDataLoading: false,
  userData: null,

  fetchUserData: async () => {
    set({
      userDataLoading: true,
    });
    const userData = await getUserInfo();
    set({
      userData,
      userDataLoading: false,
    });
  },
  login: async (email) => {
    set({
      loading: true,
    });
    try {
      await login(email);
    } finally {
      set({
        loading: false,
      });
    }
  },

  flushToken: () => {
    localStorage.setItem('token', '');
  },

  submitOtp: async (email, otp) => {
    set({
      loading: true,
    });
    try {
      const res = await submitOtp(email, otp);
      const { token } = res.data;
      localStorage.setItem('token', token);
      updateToken(token);
    } catch (error) {
      throw parseError(error);
    } finally {
      set({
        loading: false,
      });
    }
  },

  resendOtp: async (email) => {
    set({
      loading: true,
    });
    try {
      await resendOtp(email);
    } finally {
      set({
        loading: false,
      });
    }
  },

  isAuthenticated: () => {
    return !!localStorage.getItem('token');
  },
}));
