import { ReactNode, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { enqueueSnackbar, OptionsWithExtraProps } from 'notistack';

export function useNotification() {
  const intl = useIntl();

  return {
    showError: useCallback((message: ReactNode, options?: OptionsWithExtraProps<'error'>) => {
      enqueueSnackbar(message, {
        variant: 'error',
        ...options,
      });
    }, []),
    showErrorIntl: useCallback((message: string, options?: OptionsWithExtraProps<'error'>) => {
      enqueueSnackbar(intl.formatMessage({ id: message }), {
        variant: 'error',
        ...options,
      });
    }, []),
    showSuccess: useCallback((message: ReactNode, options?: OptionsWithExtraProps<'success'>) => {
      enqueueSnackbar(message, {
        variant: 'success',
        ...options,
      });
    }, []),
    showSuccessIntl: useCallback((message: string, options?: OptionsWithExtraProps<'success'>) => {
      enqueueSnackbar(intl.formatMessage({ id: message }), {
        variant: 'success',
        ...options,
      });
    }, []),
  };
}
