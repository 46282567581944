import { styled } from '@mui/material';
import { Box } from '.';

export const RotatingBox = styled(Box, {
  shouldForwardProp: (prop: string) => !['active', 'activeAngle', 'inactiveAngle'].includes(prop),
})<{ active: boolean; activeAngle: number; inactiveAngle: number }>`
  transform: ${({ active, activeAngle, inactiveAngle }) =>
    active ? `rotate(${activeAngle}deg)` : `rotate(${inactiveAngle}deg)`};
  transition: transform 0.3s;
`;
