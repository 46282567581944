import { forwardRef, PropsWithChildren } from 'react';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';

export type ButtonProps = MuiButtonProps & {
  rounded?: boolean;
};

export const Button = forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>(function Button(
  { children, rounded = true, sx, ...props },
  ref,
) {
  return (
    <MuiButton
      sx={{
        borderRadius: rounded ? '100px' : undefined,
        px: 2,
        ...sx,
      }}
      ref={ref}
      {...props}
    >
      {children}
    </MuiButton>
  );
});
