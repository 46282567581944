export function assignShift() {
  // TODO: use real API
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, 1000);
  });
}

export function unassignShift() {
  // TODO: use real API
  // return Promise.reject(new Error('Not implemented'));
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, 1000);
  });
}
