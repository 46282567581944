import Chip from '@mui/material/Chip';
import { alpha, styled } from '@mui/material/styles';

export const ShiftRecurrenceChip = styled(Chip, {
  shouldForwardProp: (value: string) => !['type'].includes(value),
})<{
  type: 'single' | 'series' | 'rotation';
}>(({ theme, type }) => {
  // `series` has a little bit different border color behavior
  return {
    borderColor: type === 'series' ? 'rgba(209, 196, 233, 1)' : alpha(theme.palette.shiftRecurrence[type].main, 0.5),
    color: theme.palette.shiftRecurrence[type].main,
  };
});
