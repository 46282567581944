import { Experiment, ExperimentClient } from '@amplitude/experiment-js-client';

const FLAG_KEYS = {} as const;

type flagsCollectionKey = keyof typeof FLAG_KEYS;
type FlagKey = (typeof FLAG_KEYS)[flagsCollectionKey];

/**
 *
 * @static
 * @export
 * @class FeatureFlags
 */
export class FeatureFlags {
  protected static _experiment: ExperimentClient | null = null;
  protected static _hasInitialized: boolean = false;

  static async init(key: string) {
    this._experiment = Experiment.initializeWithAmplitudeAnalytics(key);
    await this._experiment.start();
    this._hasInitialized = true;
  }

  static variant(flagKey: FlagKey| string) {
    if (!this._experiment || !this._hasInitialized) {
      throw new Error('Experiment lib is not yet initialized');
    }
    return this._experiment.variant(flagKey);
  }

  static get FLAGS() {
    return FLAG_KEYS;
  }
}
