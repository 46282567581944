import { useTheme } from '@mui/material';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { duotoneIcons } from './faIconsSets/duotone';
import { regularIcons } from './faIconsSets/regular';
import { lightIcons } from './faIconsSets/light';
import { solidIcons } from './faIconsSets/solid';

export type IconProps = Omit<FontAwesomeIconProps, 'icon'> & {
  name: IconName;
  color?: string;
  className?: string;
  variant?: keyof typeof icons;
  fontSize?: number;
};
export const FaIcon = ({ name, color, className, variant = 'regular', fontSize, ...props }: IconProps) => {
  const theme = useTheme();
  const byVariant = icons[variant];
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const icon = byVariant[name];

  return (
    <FontAwesomeIcon
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      icon={icon}
      color={color ?? theme.palette.text.secondary}
      className={className}
      style={{ fontSize }}
      {...props}
    />
  );
};

const icons = {
  regular: regularIcons,
  light: lightIcons,
  duotone: duotoneIcons,
  solid: solidIcons,
};

export type IconName = keyof typeof regularIcons;
