import { Filter } from '@libs/models/common';

export function filterToQueryParams(
  filters: {
    field: string;
    value: Filter['value'];
  }[],
) {
  return filters?.reduce<Record<string, string[] | string | number>>((acc, filter) => {
    if (typeof filter.value === 'object' && !Array.isArray(filter.value)) {
      return {
        ...acc,
        [`${filter.field}_from`]: [filter.value.from.toISOString()],
        [`${filter.field}_to`]: [filter.value.to.toISOString()],
      };
    }
    return { ...acc, [`${filter.field}_in`]: filter.value };
  }, {});
}
