import { create } from 'zustand';
import { getLocations } from '@libs/api/locations';
import { Location } from '@libs/models/locations';

type State = {
  locations: Location[];
  loading: boolean;
  loadLocations: () => Promise<void>;
};

export const useLocationStore = create<State>()((set) => ({
  locations: [],
  loading: false,
  loadLocations: async () => {
    try {
      set({ loading: true });
      const { data } = await getLocations({
        size: 100,
        page: 1,
      });

      set({ locations: data });
    } finally {
      set({ loading: false });
    }
  },
}));
