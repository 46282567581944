import { useTheme } from '@mui/material/styles';
import { RoundPaper, RoundPaperProps } from './roundPaper';

export type PrimaryPaperProps = RoundPaperProps;

export function PrimaryPaper({ sx, ...props }: PrimaryPaperProps) {
  const theme = useTheme();

  return (
    <RoundPaper
      sx={{
        bgcolor: `color-mix(in srgb, #fff 100%, ${theme.palette.primary.main} 8%)`,
        ...(typeof sx === 'function' ? sx(theme): sx),
      }}
      {...props}
    />
  );
}
