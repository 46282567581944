import { create } from 'zustand';
import { fetchShiftsList } from '@libs/api/shifts/fetchShiftsList';
import { Shift } from '@libs/models/shifts';

type State = {
  loading: boolean;
  initiated: boolean;
  data: Shift[];
  page: number;
  total: number;
  perPage: number;
  load: (page: number, perPage: number, org: string) => void;
  showDetails: boolean;
  currentShift: Shift | null;
  openShiftDetails: (id: string) => void;
  closeShiftDetails: () => void;
};

export const useShiftsListStore = create<State>()((set, get) => ({
  loading: false,
  initiated: false,
  data: [],
  page: 1,
  perPage: 10,
  total: 0,
  load: async (page, perPage, org) => {
    set({ loading: true });
    try {
      const resp = await fetchShiftsList(page, perPage, org);
      set({
        loading: false,
        data: resp.data,
        page,
        perPage,
        total: resp.meta.total,
      });
    } finally {
      set({ loading: false, initiated: true });
    }
  },
  currentShift: null,
  showDetails: false,
  openShiftDetails: (id) => {
    const { data } = get();
    const shift = data.find((s) => s.id === id);
    set({ currentShift: shift, showDetails: true });
  },
  closeShiftDetails: () => {
    set({ showDetails: false });
  },
}));
