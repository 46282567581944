import { SystemUser } from '@libs/models/common';
import { request } from '../request';

export function login(email: string) {
  return request.get('/v2/identity/login-otp', {
    params: {
      email,
    },
  });
}

export async function getUserInfo(): Promise<SystemUser> {
  const response = await request.get<SystemUser>('/v2/identity/info');
  return response.data;
}
