import { alpha, useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { DuotoneIcon } from './DuotoneIcon';
import { Flex } from '@atoms/layout';

type StepIconProps = {
  active?: boolean;
  done?: boolean;
  step?: number;
};
export function StepIcon({ active, done, step }: StepIconProps) {
  const theme = useTheme();
  if (!done) {
    return (
      <Flex
        width={24}
        height={24}
        alignCenter
        justifyCenter
        sx={{
          border: `1px solid ${active ? theme.palette.primary.main : alpha(theme.palette.primary.main, 0.12)}`,
          color: active ? theme.palette.common.white : theme.palette.primary.main,
          borderRadius: '50%',
          background: active ? theme.palette.primary.main : theme.palette.common.white,
        }}
      >
        <Typography fontSize={12} fontWeight={500}>
          {step}
        </Typography>
      </Flex>
    );
  }
  return (
    <Flex fontSize={24} width={24} height={24}>
      <DuotoneIcon name="check-circle" active />
    </Flex>
  );
}
