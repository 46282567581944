import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs from 'dayjs';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { paperClasses } from '@mui/material/Paper';
import { capitalize } from '@mui/material/utils';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useTheme } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import { Shift, unassignReasons } from '@libs/models/shifts';
import { getShiftType } from '@libs/models/shifts/shift';
import { Box, Flex } from '@atoms/layout';
import { Select, TextField } from '@atoms/inputs';
import { LoadingButton, Button } from '@atoms/buttons';
import { FaIcon } from '@atoms/icons';

type UnassignDialogProps = Omit<DialogProps, 'onSubmit'> & {
  shift: Shift;
  onSubmit?: (reason: string, reasonComment: string) => void;
  loading?: boolean;
};
export function UnassignDialog({ shift, sx, onSubmit, loading, ...props }: UnassignDialogProps) {
  const theme = useTheme();

  return (
    <Dialog
      fullWidth
      maxWidth={'xs'}
      sx={{
        [`& .${paperClasses.root}`]: {
          bgcolor: `color-mix(in srgb, #fff 100%, ${theme.palette.primary.main} 8%)`,
        },
        ...sx,
      }}
      {...props}
    >
      <Content
        shift={shift}
        onCloseClick={() => props.onClose?.({}, 'escapeKeyDown')}
        onSubmit={onSubmit}
        loading={loading}
      />
    </Dialog>
  );
}

type ContentProps = {
  shift: Shift;
  onCloseClick: () => void;
  onSubmit?: (reason: string, reasonComment: string) => void;
  loading?: boolean;
};
function Content({ shift, onCloseClick, onSubmit, loading = false }: ContentProps) {
  const intl = useIntl();
  const [reason, setReason] = useState('');
  const [reasonComment, setReasonComment] = useState('');
  const validValue = reason === 'custom' ? reasonComment !== '' : reason !== '';
  const shiftType = getShiftType(dayjs(shift?.shiftStart));
  const to = dayjs(shift?.shiftStart).add(shift?.shiftLength ?? 0, 'millisecond');

  return (
    <>
      <DialogTitle sx={{ px: 3, pt: 4, pb: 2 }}>
        <Flex justifyContent="space-between">
          <Flex column gap={1}>
            <Typography variant="h5">
              <FormattedMessage id="shifts.unassignDialog.title" />
            </Typography>
            <Typography variant="body1" color="text.secondary">
              <FormattedMessage id="shifts.unassignDialog.subtitle" />
            </Typography>
          </Flex>
          <Box>
            <IconButton sx={{ width: '40px', position: 'relative', top: -5, right: -5 }} onClick={onCloseClick}>
              <FaIcon name="xmark" variant="light" />
            </IconButton>
          </Box>
        </Flex>
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" noWrap>
          <FormattedMessage
            id="shifts.unassignDialog.shiftDetails"
            values={{
              period: `${intl.formatTime(shift?.shiftStart, { hour: 'numeric' })} - ${intl.formatTime(to.toDate(), { hour: 'numeric' })}`,
              length: intl.formatMessage(
                { id: 'shifts.length' },
                {
                  length: Math.round((shift?.shiftLength ?? 0) / 1000 / 60 / 60),
                },
              ),
              type: capitalize(shiftType),
              qualifications: shift?.qualifications,
              assignee: shift?.assignee?.name,
            }}
          />
        </Typography>

        <Flex mt={2}>
          <Select
            options={unassignReasons.map((r) => ({
              value: r.id,
              label: r.label,
              key: r.id,
            }))}
            value={reason}
            size="small"
            required
            onChange={(e) => setReason(e.target.value)}
            fullWidth
            label={<FormattedMessage id="shifts.unassignDialog.reasonLabel" />}
            placeholder={intl.formatMessage({
              id: 'shifts.unassignDialog.reasonPlaceholder',
            })}
          />
        </Flex>
        <Collapse in={reason === 'custom'} timeout={150}>
          <Box mt={2}>
            <TextField
              value={reasonComment}
              onChange={(e) => setReasonComment(e.target.value)}
              label={<FormattedMessage id="shifts.unassignDialog.reasonCommentLabel" />}
              placeholder={intl.formatMessage({
                id: 'shifts.unassignDialog.reasonCommentPlaceholder',
              })}
              required
              fullWidth
            />
          </Box>
        </Collapse>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 3, pt: 0 }}>
        <Box color="text.secondary">
          <Button variant="text" color="inherit" rounded onClick={onCloseClick} size="large">
            <FormattedMessage id="common.cancel" />
          </Button>
        </Box>
        <LoadingButton
          loading={loading}
          variant="contained"
          rounded
          onClick={() => onSubmit?.(reason, '')}
          size="large"
          disabled={!validValue}
        >
          <FormattedMessage id="common.submit" />
        </LoadingButton>
      </DialogActions>
    </>
  );
}
