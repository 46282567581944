import { forwardRef, ReactNode } from 'react';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import MuiAutocomplete, { AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete';
import { FaIcon } from '@atoms/icons';
import { TextField, TextFieldProps } from './textField';

export type AutocompleteMultipleProps<Value extends string | number> = Omit<
  MuiAutocompleteProps<Option<Value>, true, true, false>,
  'renderInput' | 'value'
> & {
  value?: Value[];
  required?: boolean;
  label?: ReactNode;
  textFieldProps?: TextFieldProps;
  error?: boolean;
  helperText?: ReactNode;
};

export const AutocompleteMultiple = forwardRef(function AutocompleteMultiple<Value extends string | number>(
  { label, options, textFieldProps, error, helperText, value, ...props }: AutocompleteMultipleProps<Value>,
  ref: React.Ref<HTMLInputElement>,
) {
  const { fullWidth, required } = props;
  const internalValue = value?.map((v) => options.find((o) => o.value === v)).filter((v) => v !== undefined);

  return (
    <FormControl fullWidth={fullWidth}>
      <MuiAutocomplete
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={label}
              required={required}
              error={error}
              helperText={helperText}
              {...textFieldProps}
              inputRef={ref}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      right: '3px',
                      position: 'absolute',
                      top: '50%',
                      transform: 'translate(0, -50%)',
                    }}
                  >
                    <IconButton size="small">
                      <FaIcon name="angle-down" variant="light" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          );
        }}
        value={internalValue}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        options={options}
        disableClearable
        multiple
        selectOnFocus
        handleHomeEndKeys
        limitTags={2}
        getOptionLabel={(option) => option.label}
        ChipProps={{
          color: 'primary',
        }}
        {...props}
      />
    </FormControl>
  );
});

type Option<T extends string | number> = {
  value: T;
  label: string;
};
