import { forwardRef, ReactNode, useMemo, useState } from 'react';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { TextField } from './textField';
import { SearchAddressResultItemOutput } from '@azure-rest/maps-search';
import { WORKING_REGIONS } from '@framework/src/libs/config';
import { getTimeZones, TimeZone } from '@vvo/tzdb';
import { Tooltip } from '@mui/material';

type TimezoneAutocompleteProps = Omit<
  AutocompleteProps<TimeZone | string, false, false, false>,
  'options' | 'renderInput' | 'onChange' | 'value'
> & {
  onChange?: (value: TimeZone | null) => void;
  label?: ReactNode;
  fullWidth?: boolean;
  required?: boolean;
  placeholder?: string;
  value?: string | null;
  error?: string;
};
export const TimezoneAutocomplete = forwardRef<HTMLDivElement, TimezoneAutocompleteProps>(function TimezoneAutocomplete(
  { onChange, label, fullWidth, required, placeholder, value, error, ...props },
  ref,
) {
  const options = useMemo(()=>getTimeZones({ includeUtc: true })
    .filter((tz) => WORKING_REGIONS.includes(tz.countryCode?.toUpperCase()))
    .map((tz) => ({
      label: tz.currentTimeFormat,
      id: tz.currentTimeFormat,
      value: tz.currentTimeFormat,
      ...tz,
    })), [value]);
  return (
    <Autocomplete
      filterOptions={(x) => x}
      options={options}
      value={value}
      onChange={(_, newValue) => {
        // We want user to select only from the list
        // so we can fill city, state and zip fields
        if (typeof newValue !== 'string') {
          onChange?.(newValue);
        }
      }}
      isOptionEqualToValue={(option, value) => {
        const toCompare = typeof value === 'string' ? value : value.name;
        if (typeof option === 'string') {
          return toCompare === option;
        }
        return toCompare === option.name;
      }}
      renderInput={(params) => {
        return (
          <TextField
            InputLabelProps={params.InputLabelProps}
            InputProps={params.InputProps}
            inputProps={{...params.inputProps, value: value}}
            id={params.id}
            disabled={params.disabled}
            size={params.size}
            label={label}
            fullWidth={fullWidth}
            required={required}
            placeholder={placeholder}
            ref={ref}
            value={value}
            error={!!error}
            helperText={error}
          />
        );
      }}
      renderOption={(props, option) => {
        // eslint-disable-next-line react/prop-types
        const { id, ...optionProps } = props;
        return (
          <li {...optionProps} key={id}>
            <Grid container sx={{ alignItems: 'center' }}>
              <Tooltip title={typeof option === 'string' ? option : `Main cities: ${option.mainCities.join(', ')}`}>
                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                  <Typography variant="body1">
                    {typeof option === 'string'
                      ? option
                      : `${option.abbreviation} ${option.name} ${option.alternativeName}`}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {typeof option === 'string' ? '' : option.currentTimeFormat}
                  </Typography>
                </Grid>
              </Tooltip>
            </Grid>
          </li>
        );
      }}
      {...props}
    />
  );
});

export type Place = {
  description: string;
  details: string;
  city: string;
  state: string;
  zip: string;
  original: SearchAddressResultItemOutput;
};
