export function capitalize(str: string) {
  if (str.length === 0) {
    throw new Error('Empty string');
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getFullName(firstName?: string, lastName?: string) {
  return [firstName, lastName].filter(Boolean).join(' ');
}

export function getFullNameAbbr(fullName: string, maxLength = 2) {
  return fullName
    .split(' ')
    .map((n) => n[0])
    .splice(0, maxLength)
    .join('');
}
