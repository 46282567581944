import * as amplitude from '@amplitude/analytics-browser';
import { getDeviceId } from './helpers';

/**
 * @static
 * @class Analytics
 */
export class Analytics {
  static init(token: string | undefined) {
    if (token) {
      amplitude.setOptOut(false); //just in case
      amplitude.init(token, { autocapture: true });
      amplitude.setDeviceId(getDeviceId());
    } else {
      amplitude.setOptOut(true);
    }
  }

  static async track(
    eventInput: string,
    eventProperties?: Record<string, any>,
    eventOptions?: amplitude.Types.EventOptions,
  ) {
    // add any additional prefix\postfix for event names
    const evtName = eventInput;
    // add any default properties for all events around the app
    const evtProps = {
      'Page Domain': window.location.hostname,
      'Page Path': window.location.pathname,
      ...(eventProperties || {}),
    };
    // add any custom event options for all events
    const opts = { ...(eventOptions || {}) };
    return amplitude.track(evtName, evtProps, opts);
  }
}
