import { StepContextType, useStepContext } from '@mui/material/Step';
import Typography from '@mui/material/Typography';
import StepLabel, { stepLabelClasses, StepLabelProps } from '@mui/material/StepLabel';
import { StepIcon } from '@atoms/icons';

type MenuStepLabelProps = StepLabelProps & {
  done?: boolean;
};
export function MenuStepLabel({ done, ...props }: MenuStepLabelProps) {
  const { active, index = 0 } = useStepContext() as Partial<StepContextType>;

  return (
    <StepLabel
      sx={{
        [`& .${stepLabelClasses.labelContainer}`]: {
          pl: 1,
        },
      }}
      icon={<StepIcon active={active} done={done} step={index + 1} />}
      {...props}
    >
      <Typography variant={active ? 'subtitle1' : 'body1'}>{props.children}</Typography>
    </StepLabel>
  );
}
