import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { listItemIconClasses } from '@mui/material/ListItemIcon';
import { paperClasses } from '@mui/material/Paper';
import { chipClasses } from '@mui/material/Chip';
import '@mui/material/styles/createPalette';
import { alpha, createTheme, ComponentsOverrides, ComponentsVariants, Theme as MuiTheme } from '@mui/material/styles';
import { DateRangeSelectorProps } from '@atoms/inputs';

export const drawerWidth = 120;
export const headerHeight = 64;
export const baseTheme = createTheme({
  palette: {
    primary: {
      main: '#075CDA',
    },
    secondary: {
      main: 'rgba(30, 43, 60, 1)',
    },
    success: {
      main: 'rgba(15, 136, 19, 1)',
    },
    warning: {
      main: '#FB4803',
    },
    applicationBackground: 'rgba(7, 92, 218, 0.16)',
    shiftType: {
      day: '#F7E06E',
      eve: '#D985E0',
      noc: '#ABB2BA',
    },
    shiftRecurrence: {
      single: { main: '#3F5169' },
      series: { main: '#673AB7' },
      rotation: { main: '#0288D1' },
    },
  },
  components: {
    MuiSkeleton: {
      variants: [
        {
          props: { variant: 'rounded' },
          style: {
            borderRadius: '5000px',
          },
        },
      ],
    },
    MuiAppBar: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: 'rgba(255, 255, 255, 0)',
          boxShadow: 'none',
          color: theme.palette.text.primary,
        }),
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          borderRadius: ownerState.size === 'small' ? '20px' : '28px',
          background: theme.palette.common.white,
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: 'rgba(0, 0, 0, 0.06)',
          },
          [`&.${outlinedInputClasses.disabled} .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: 'rgba(0, 0, 0, 0.06)',
          },
          [`&:hover:not(&.${outlinedInputClasses.error}) .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
          [`&.${outlinedInputClasses.focused}:not(&.${outlinedInputClasses.error}) .${outlinedInputClasses.notchedOutline}`]:
            {
              borderColor: theme.palette.primary.main,
              borderWidth: '1px',
            },
        }),
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        sizeLarge: {
          height: '40px',
        },
        sizeSmall: {
          padding: '5px 10px',
          lineHeight: '22px',
        },
      },
      variants: [
        {
          props: { variant: 'passive' },
          style: ({ theme }) => ({
            color: theme.palette.text.secondary,
          }),
        },
      ],
    },
    MuiButtonGroup: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        sizeLarge: {
          height: '40px',
          fontSize: '16px',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '16px',
        },
        root: {
          [`& .${listItemIconClasses.root}`]: {
            fontSize: '1.3rem',
            margin: '4px',
            minWidth: '43px',
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      variants: [
        {
          // To override the borderRadius in default variant
          props: ({ ownerState }) => !ownerState.square,
          style: ({ theme }) => ({
            borderRadius: 4 * theme.shape.borderRadius,
          }),
        },
      ],
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme, ownerState }) => {
          return {
            backgroundColor: (() => {
              if (ownerState.variant !== 'filled' || !ownerState.color) {
                return undefined;
              }

              if (ownerState.color === 'default') {
                return alpha(theme.palette.action.hover, 0.04);
              }

              return alpha(theme.palette[ownerState.color].main, 0.04);
            })(),
            color: (() => {
              if (ownerState.variant !== 'filled' || !ownerState.color || ownerState.color === 'default') {
                return undefined;
              }

              return theme.palette[ownerState.color].main;
            })(),
            [`& .${chipClasses.deleteIcon}`]: {
              color: theme.palette.grey[500],
            },
          };
        },
        label: {
          paddingLeft: 10,
          paddingRight: 10,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.23)',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#9E9E9E',
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        tag: ({ theme }) => ({
          color: theme.palette.text.primary,
          [`&:not(.${chipClasses.root})`]: {
            backgroundColor: alpha(theme.palette.primary.main, 0.12),
            borderRadius: '12px',
            padding: '0 8px',
          },
        }),
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: ({ theme }) => ({
          [`& .${paperClasses.root}`]: {
            boxShadow: theme.shadows[2],
          },
        }),
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'h5' },
          style: {
            fontWeight: 500,
            cursor: 'default',
          },
        },
        {
          props: { variant: 'subtitle1' },
          style: {
            fontWeight: 500,
            cursor: 'default',
          },
        },
      ],
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          color: 'rgba(0, 0, 0, 0.87)',
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: ({ theme }) => ({
          borderLeftColor: alpha(theme.palette.primary.main, 0.12),
        }),
      },
    },
    DateRangeSelector: {
      styleOverrides: {
        button: {
          '&:hover': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    DelayedLinearProgress: {
      loadingDelay: 600, //ms
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
      },
    },
  },
});

declare module '@mui/material/styles/createPalette' {
  // To set value in the theme
  export interface PaletteOptions {
    applicationBackground: string;
    shiftType: {
      day: string;
      eve: string;
      noc: string;
    };
    shiftRecurrence: {
      single: PaletteColorOptions;
      series: PaletteColorOptions;
      rotation: PaletteColorOptions;
    };
  }

  // To be able to use it from the theme
  export interface Palette {
    applicationBackground: string;
    shiftType: {
      day: string;
      eve: string;
      noc: string;
    };
    shiftRecurrence: {
      single: PaletteColor;
      series: PaletteColor;
      rotation: PaletteColor;
    };
  }
}

type Theme = Omit<MuiTheme, 'components'>;

declare module '@mui/material/styles' {
  interface ComponentNameToClassKey {
    DateRangeSelector: 'button';
  }

  interface ComponentsPropsList {
    DateRangeSelector: Partial<DateRangeSelectorProps>;
  }

  interface Components {
    DateRangeSelector?: {
      defaultProps?: ComponentsPropsList['DateRangeSelector'];
      styleOverrides?: ComponentsOverrides<Theme>['DateRangeSelector'];
      variants?: ComponentsVariants['DateRangeSelector'];
    };
    DelayedLinearProgress?: {
      loadingDelay?: number; // ms
    };
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    passive: true;
  }
}
