import { forwardRef, ReactNode, useId } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import MuiRadioGroup, { RadioGroupProps as MuiRadioGroupProps } from '@mui/material/RadioGroup';
import { FormLabel } from './formLabel';

export type RadioGroupProps = MuiRadioGroupProps & {
  label?: ReactNode;
  items?: RadioItem[];
  disabled?: boolean;
  required?: boolean;
};
export const RadioGroup = forwardRef<HTMLButtonElement, RadioGroupProps>(function RadioGroup(
  { label, id, items = [], disabled, required, ...props },
  ref,
) {
  const generatedId = useId();
  const idInternal = id ?? generatedId;

  return (
    <FormControl>
      <FormLabel disabled={disabled} required={required}>
        {label}
      </FormLabel>
      <MuiRadioGroup aria-labelledby={idInternal} ref={ref} {...props}>
        {items.map(({ label, value }) => (
          <FormControlLabel key={value} value={value} control={<Radio />} label={label} disabled={disabled} />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
});

type RadioItem = {
  label: ReactNode;
  value: string | number;
};
