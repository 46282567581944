import { create } from 'zustand';

type CurrentAgencyState = {
  id: string | null;
  setCurrentAgency: (id: string) => void;
};

export const useCurrentAgency = create<CurrentAgencyState>()((set) => ({
  // TODO: Replace with actual agency ID
  id: '1',
  setCurrentAgency: (id) => {
    set({ id });
  },
}));
