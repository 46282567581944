import { Specialty } from '@libs/models/talents';
import { request } from '../request';

export function fetchSpecialities(params: GetSpecialitiesParams) {
  return request.get<SpecialitiesResponse>('/v2/settings/specialities', { params });
}

export type GetSpecialitiesParams = {
  page?: number;
  size?: number;
};

type SpecialitiesResponse = {
  data: Specialty[];
  total: number;
};
