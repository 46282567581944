import Paper, { PaperProps } from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';

export type RoundPaperProps = PaperProps;

export function RoundPaper({ children, sx, ...props }: RoundPaperProps) {
  const theme = useTheme();

  return (
    <Paper
      sx={{
        bgcolor: theme.palette.background.paper,
        p: 2,
        ...(typeof sx === 'function' ? sx(theme): sx),
      }}
      {...props}
    >
      {children}
    </Paper>
  );
}
