import { PropsWithChildren } from 'react';
import Stepper, { StepperProps } from '@mui/material/Stepper';
import { MenuStepperStep } from './menuStepperStep';
import { MenuStepLabel } from './menuStepLabel';
import { stepConnectorClasses } from '@mui/material';

type MenuStepperProps = StepperProps;
export function MenuStepper({ children, sx, ...props }: PropsWithChildren<MenuStepperProps>) {
  return (
    <Stepper
      orientation="vertical"
      sx={{
        [`& .${stepConnectorClasses.root}`]: {
          my: -1.5,
          ml: '27px',
        },
        [`& .${stepConnectorClasses.line}`]: {
          minHeight: '36px',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Stepper>
  );
}

MenuStepper.Step = MenuStepperStep;
MenuStepper.Label = MenuStepLabel;
