import { forwardRef } from 'react';
import { DatePicker as MuiDatePicker, DatePickerProps as MuiDatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';
import { TextField } from './textField';

export type DatePickerProps = MuiDatePickerProps<Dayjs> & {
  fullWidth?: boolean;
  error?: boolean;
  required?: boolean;
  helperText?: React.ReactNode;
};
export const DatePicker = forwardRef<HTMLDivElement, DatePickerProps>(function DatePicker(
  { fullWidth, error, helperText, required, ...props }: DatePickerProps,
  ref,
) {
  return (
    <MuiDatePicker
      slots={{ textField: TextField }}
      slotProps={{
        textField: {
          fullWidth,
          required,
          error,
          helperText,
          inputRef: ref,
          InputProps: {
            sx: {
              borderRadius: '28px',
            },
          },
        },
      }}
      {...props}
    />
  );
});
