// React Intl v2 no longer supports nested messages objects, instead the collection of translated string messages passed to <IntlProvider> must be flat.
// But nested messages are easier to manage and read, so we can flatten them.
// https://formatjs.io/docs/react-intl/upgrade-guide-2x/#flatten-messages-object
export function flattenMessages(nestedMessages: NestedMessages, prefix = ''): FlattenedMessages {
  if (nestedMessages === null) {
    return {};
  }
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      Object.assign(messages, { [prefixedKey]: value });
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
}

type NestedMessages = {
  [key: string]: string | NestedMessages;
};

type FlattenedMessages = {
  [key: string]: string;
};
