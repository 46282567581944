import { create } from 'zustand';
import { ShiftBilling } from '@libs/models/shifts';

type State = {
  shiftBilling: ShiftBilling | null;
  loading: boolean;
  shiftId: string | null;
  loadBilling: (shiftId: string) => void;
  updateBilling: (shiftBilling: ShiftBilling) => void;
};

export const useShiftBillingStore = create<State>()((set, get) => ({
  shiftBilling: null,
  loading: false,
  shiftId: null,
  loadBilling: async (newId: string) => {
    const { shiftId } = get();
    const sameId = shiftId === newId;
    // Suppress loading state if the shiftId is the same
    set({ loading: !sameId });
    if (!sameId) {
      // Clear notes if the shiftId is different
      set({ shiftBilling: null });
    }

    await new Promise((resolve) => setTimeout(resolve, 1000));

    set({
      shiftBilling: {
        billRate: 27,
        bonusRateType: 'perHour',
        payRate: 24,
        billAmount: 216,
        payAmount: 192,
        grossMargin: 24,
        totalTime: 8,
      },
      loading: false,
      shiftId: newId,
    });
  },
  updateBilling: (shiftBilling) => set({ shiftBilling }),
}));
