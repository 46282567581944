export const unassignReasons = [
  { id: 'sick', label: 'Sick' },
  { id: 'sickChild', label: 'Sick child' },
  { id: 'noShow', label: 'No show' },
  { id: 'agencyError', label: 'Agency error' },
  { id: 'anotherAssignment', label: 'Picked up another assignment' },
  { id: 'noInterested', label: 'No longer interested' },
  { id: 'selfDnrForTheFacility', label: 'Employee self DNR for the facility' },
  { id: 'employeeTerminated', label: 'Employee terminated' },
  { id: 'custom', label: 'Other' },
];
