import { Dayjs } from 'dayjs';
import { FormattedTime } from 'react-intl';

type TimeRangeProps = {
  from: Dayjs;
  to: Dayjs;
};
export function TimeRange({ from, to }: TimeRangeProps) {
  return (
    <>
      <FormattedTime value={from.toDate()} />
      {' - '}
      <FormattedTime value={to.toDate()} />
    </>
  );
}
