import { PropsWithChildren } from 'react';
import '@mui/material/styles/createPalette';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { baseTheme } from '@shiftnex/framework/src/theme';

export const drawerWidth = 120;
export const headerHeight = 64;
const theme = createTheme({
  palette: {
    ...baseTheme.palette,
  },
  components: {
    ...baseTheme.components,
  },
});

export function ThemeProvider({ children }: PropsWithChildren) {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
