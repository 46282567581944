import { PropsWithChildren } from 'react';
import { useTheme } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { RequiredAsterisk } from './requiredAsterisk';

export type FormLabelProps = TypographyProps & {
  disabled?: boolean;
  required?: boolean;
};
export function FormLabel({ disabled, children, required }: PropsWithChildren<FormLabelProps>) {
  const theme = useTheme();
  const asterisk = required ? <RequiredAsterisk /> : '';
  return (
    <Typography variant="subtitle2" color={disabled ? theme.palette.text.disabled : undefined}>
      {children} {asterisk}
    </Typography>
  );
}
