import { useIntl } from 'react-intl';
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';
import { Autocomplete, AutocompleteOption, type AutocompleteProps } from '@atoms/inputs';

export type FormAutocompleteProps<T extends FieldValues, O extends AutocompleteOption> = Omit<
  AutocompleteProps<O>,
  'name'
> & {
  name: Path<T>;
  requiredMessage?: string;
};
export function FormAutocomplete<O extends AutocompleteOption, T extends FieldValues>({
  name,
  requiredMessage,
  ...props
}: FormAutocompleteProps<T, O>) {
  const intl = useIntl();
  const { required } = props;
  const { control } = useFormContext<T>();
  const rules = {
    ...(required
      ? {
          required: requiredMessage ?? intl.formatMessage({ id: 'forms.fieldIsRequired' }),
        }
      : {}),
  };

  return (
    <Controller<T>
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, ...field }, fieldState: { error } }) => {
        return (
          <Autocomplete
            {...props}
            onChange={(_, item) => {
              onChange(item?.value);
            }}
            {...field}
            error={!!error}
            helperText={error?.message}
          />
        );
      }}
    />
  );
}
