import { StateCreator } from 'zustand';
import { FilterGeneral } from '@libs/models/common/filter';
import { Filter } from '@libs/models/common';

export const createDefaultFilterSlice: StateCreator<DefaultFilterState, [], [], DefaultFilterState> = (set, get) => ({
  activeFilters: [],
  setFilter: (filter) => {
    set((state) => {
      // Empty array, removing filter
      if (Array.isArray(filter.value) && filter.value.length === 0) {
        return {
          activeFilters: state.activeFilters.filter((f) => f.key !== filter.key),
        };
      }
      // Empty string, removing filter
      if (typeof filter.value === 'string' && filter.value.length === 0) {
        return {
          activeFilters: state.activeFilters.filter((f) => f.key !== filter.key),
        };
      }
      const existingFilterIndex = state.activeFilters.findIndex((f) => f.key === filter.key);
      const newFilters = [...state.activeFilters];
      if (existingFilterIndex > -1) {
        newFilters[existingFilterIndex] = filter;
      } else {
        newFilters.push(filter);
      }
      return { activeFilters: newFilters };
    });
  },
  clearFilter: (key) => {
    set((state) => ({
      activeFilters: state.activeFilters.filter((f) => f.key !== key),
    }));
  },
  clearFilters: () => {
    set(() => ({
      activeFilters: [],
    }));
  },
  getFilter: <T>(key: string) => {
    const filter = get().activeFilters.find((f) => f.key === key) as T | undefined;
    return filter || null;
  },
  setFilterFromOptions: (key: string, value: string[], options: { value: string; label: string }[]) => {
    get().setFilter({
      key,
      valueView: value
        .map((l) => options.find((s) => s.value === l)?.label)
        .filter(Boolean)
        .join(', '),
      value,
    });
  },
});

export type DefaultFilterState = {
  activeFilters: Filter[];
  setFilter: (filter: Filter) => void;
  clearFilter: (key: string) => void;
  clearFilters: () => void;
  getFilter: <T>(key: string) => FilterGeneral<T> | null;
  setFilterFromOptions: (key: string, value: string[], options: { value: string; label: string }[]) => void;
};
