import { flattenMessages } from '../flattenMessages';

export default flattenMessages({
  common: {
    saveChanges: 'Save changes',
    save: 'Save',
    cancel: 'Cancel',
    submit: 'Submit',
    weekDays: {
      sunday: 'Sunday',
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
    },
    greeting: 'Hello, {name}!',
    clear: 'Clear',
    applyFilters: 'Apply filters',
    back: 'Back',
    next: 'Next',
    search: 'Search',
    filter: 'Filter',
    all: 'All',
    name: 'Name',
    clearAllFilters: 'Clear all filters',
    byPerson: 'by {name}',
    actions: 'Actions',
    assignDialog: {
      title: 'Assign',
      placeholder: 'Please, specify the value',
    },
    status: 'Status',
    location: 'Location',
    yes: 'Yes',
    no: 'No',
    unknownError: 'Something went wrong',
  },
  auth: {
    login: {
      title: 'Login',
      subtitle: 'Please, provide your email or phone number to receive an OTP',
      tabEmailTitle: 'Email',
      tabPhoneTitle: 'Phone number',
      emailPlaceholder: 'e.g. jhondoe@gmail.com',
      signUpText: 'Don’t have a ShiftNex account yet? <link>Sign Up</link>',
    },
    otp: {
      title: 'Secure verification',
      subtitle: 'We’ve just sent you a temporary 6-digit code verification. Please check email and/or phone.',
      inputPlaceholder: 'Enter the code',
      resendCode: 'Resend the code',
      resendCodeSuccess: 'Code successfully resent',
    },
    adminSignUp: {
      title: 'Welcome to ShiftNex!',
      titleWithName: '{name}, welcome to ShiftNex!',
      subtitle: 'Join the ShiftNex Healthcare Platform and enjoy the way we manage healthcare staffing operations',
      otpBySmsLabel: 'Receive OTP by sms',
      otpBySmsInstructions: 'Please, fill in your phone number if you want receive the one-time password  by SMS',
      submitLabel: 'Create account',
    },
    adminSignUpDetails: {
      title: 'Agency',
      subtitle: 'Please, fill in your agency details.',
    },
    adminSignUpAddress: {
      title: 'Address',
      subtitle: 'Please, fill in your agency address details.',
    },
    footer: {
      legalMessage:
        'By using {name} you agree to the <termsLink>Terms of Service</termsLink> and <privacyLink>Privacy Policy</privacyLink>',
      version: 'Version {version}',
    },
    submitLabel: 'Continue',
    signInText: 'Already have a ShiftNex account? <link>Sign In</link>',
  },
  sidebar: {
    poweredBy: 'Powered by ShiftNex',
    dashboard: 'Dashboard',
    clients: 'Clients',
    shifts: 'Shifts',
    talent: 'Providers',
    contacts: 'Contacts',
    marketplace: 'Marketplace',
    settings: 'Settings',
    billing: 'Billing',
    help: 'Help',
  },
  onboarding: {
    progress: {
      gettingStarted: 'Getting started',
      menuHeader: 'Onboarding',
    },
    organization: {
      title: 'Organization setup',
      subtitle: 'Fill in your organization details',
      subtitleLong:
        'Please fill in your organization information so the ShiftNex can proceed with verifying your account',
    },
    branding: {
      title: 'Branding',
      subtitle: 'Set up your agency’s unique style',
      subtitleLong: 'Set up your agency’s unique style.',
    },
    users: {
      title: 'Roles & Users',
      titleAlternative: 'Roles and users',
      subtitle: 'Add users and set up the roles',
      subtitleLong:
        'Add the users of your organization. Customize the roles to provide the access to your organization’s users.',
    },
    clients: {
      title: 'Clients',
      subtitle: 'Set up the relationship with facilities',
      subtitleLong: 'Set up the relationship with the facilities to fill in the shifts shared with you.',
    },
    talents: {
      title: 'Providers',
      subtitle: 'Add providers working in your agency',
      subtitleLong: 'Add the providers working in your agency so you can assign them to the shared shifts.',
    },
    shifts: {
      title: 'Shifts',
      subtitle: 'Create and assign shifts',
      subtitleLong: 'Check for the shifts shared with you and assign your providers.',
    },
    widget: {
      hide: 'Hide',
      previousStep: 'Previous step',
      nextStep: 'Next step',
      addDetails: 'Add details',
      setBranding: 'Set branding',
      getStarted: 'Get started',
    },
  },
  license: {
    trial: {
      daysLeft: '{days} days left in trial',
      upgradeBtnLabel: 'Upgrade',
    },
  },
  header: {
    manageAccount: 'Manage account',
    profileMenu: {
      pauseNotificationsLabel: 'Pause notifications',
      profileSettingsLabel: 'Profile settings',
      supportLabel: 'Support chat',
      reportLabel: 'Report an issue',
      logOutLabel: 'Log out',
    },
    notificationSchedule: {
      label: 'Pause notifications for...',
      postponeMins: 'For {value, plural, one {# minute} other {# minutes}}',
      postponeHours: 'For {value, plural, one {# hour} other {# hours}}',
      postponeTomorrow: 'Until tomorrow',
    },
  },
  comingSoon: {
    title: 'Coming soon...',
    description: 'The page is not yet available but we are working on it',
  },
  settings: {
    menu: {
      general: {
        title: 'General',
      },
      organization: {
        title: 'Organization',
      },
      rolesAndUsers: {
        title: 'Roles and users',
      },
      groups: {
        title: 'Access groups',
      },
      users: {
        title: 'System users',
      },
      privacyAndSecurity: {
        title: 'Privacy and security',
      },
      organizationDetails: {
        title: 'Organization details',
      },
      documents: {
        title: 'Documents',
      },
      branding: {
        title: 'Branding',
      },
    },
    organization: {
      savedSuccessfully: 'Organization details saved successfully',
      title: 'Organization details',
      subtitle: 'Please fill in your organization information so the ShiftNex can proceed with verifying your account',
      mainDetails: {
        title: 'Main details',
        subtitle: 'Please, fill in organization contact details and EIN',
      },
      address: {
        title: 'Address details',
        subtitle: 'Please, fill in your agency address details.',
      },
      contact: {
        title: 'Main organization contact',
        subtitle: 'Please, fill in contact details',
      },
      operational: {
        title: 'Operational setup',
        subtitle: 'Set up the way organization operates',
      },
    },
    users: {
      title: 'System users',
      subtitle: 'Each user will belong to one of the roles below, and different roles have their unique privilege',
    },
  },
  forms: {
    requiredFieldsMissing: '{number} mandatory fields missing',
    fieldIsRequired: 'This field is required',
    maxLengthError: 'Max length is {number}',
    invalidPhoneNumber: 'Please, enter valid phone number',
    invalidEIN: 'Please, check the format of the input',
    invalidZipCode: 'Please, enter valid zip code',
    invalidEmail: 'Please, enter valid email',
    emptyLinesOfBusiness: 'Select at least one business line of the organization',
    invalidWebsite: 'Please, enter valid website',
  },
  fields: {
    firstName: 'First name',
    firstNamePlaceholder: 'e.g. Jhon',
    lastName: 'Last name',
    lastNamePlaceholder: 'e.g. Doe',
    legalName: 'Legal name',
    agencyName: 'Agency name',
    agencyNamePlaceholder: 'Enter agency name',
    doingBusinessAs: 'Doing business as',
    agencyType: 'Agency type',
    agencyTypePlaceholder: 'Select agency type',
    registrationDate: 'Registration date',
    organizationWebsite: 'Organization website',
    organizationWebsitePlaceholder: 'Enter organization website',
    phone: 'Phone',
    phonePlaceholder: '+1 (XXX) XXX-XXXX',
    employerIdentificationNumber: 'Employer Identification Number (EIN)',

    primaryContactsFullName: 'Primary contact’s full name',
    primaryContactsFullNamePlaceholder: 'Enter primary contact’s full name',
    jobTitle: 'Job title',
    jobTitlePlaceholder: 'Enter job title',
    primaryPhoneNumber: 'Primary phone number',
    useOrganizationPhoneNumber: 'Use organization phone number',
    secondaryPhoneNumber: 'Secondary phone number',
    email: 'Email',
    emailPlaceholder: 'e.g. jhondoe@gmail.com',
    organizationsContactEmail: 'Enter organization’s contact email',

    addressLine1: 'Street address 1',
    addressLine1NoLocations: 'No locations',
    addressLine2: 'Street address 2',
    addressLinePlaceholder: 'Enter street name, number',
    cityAndState: 'City & State',
    zipCode: 'Zip code',

    weekStartDay: 'Working week start',
    linesOfBusiness: 'Lines of business of the organization',
    linesOfBusinessShort: 'Lines of business',
    linesOfBusinessPlaceholder: 'Select lines of business',
    jobQualifications: 'Provider Job Qualifications',
    jobQualificationsPlaceholder: 'Select qualifications that apply',
    qualifications: 'What qualifications do you staff?',
    qualificationsPlaceholder: 'Select the qualifications you staff',
    specialty: 'Specialty',

    talents: 'Providers',
    statuses: 'Statuses',

    minValueError: 'Minimum value is {value}',
  },
  shifts: {
    type: {
      shifts: 'Shifts',
      series: 'Series & Rotations',
    },
    recurrence: {
      single: 'Single',
      series: 'Series',
      rotation: 'Rotation',
    },
    status: {
      assigned: 'Assigned',
      reAssigned: 'Re-assigned',
      pending: 'Pending',
      inProgress: 'In progress',
      completed: 'Completed',
      canceled: 'Canceled',
      na: 'N/A',
    },
    shiftType: {
      day8: '8h Day',
      eve8: '8h Eve',
      noc8: '8h Noc',
      day12: '12h Day',
      noc12: '12h Noc',
    },
    table: {
      noRows: {
        title: 'No shifts yet',
        subtitle: 'You don’t have any shifts yet',
      },
    },
    fields: {
      shiftType: 'Shift type',
      recurrence: 'Recurrence',
      location: 'Location',
      qualification: 'Qualification',
      qualifications: 'Qualifications',
      specialty: 'Specialty',
      status: 'Status',
      notes: 'Notes',
      assignee: 'Assignee',
      startDate: 'Start Date',
      shiftDate: 'Shift date',
      sharedWithin: 'Shared within',
      cityAndState: 'City & State',
      locationName: 'Location name',
      address: 'Address',
      department: 'Department',
      subDepartment: 'Sub department',
      talents: 'Providers',
    },
    lengthAndType: '{length}h, {type}',
    length: '{length}h',
    emptyNote: 'There is no note related to the shift',
    assignBtnLabel: 'Assign',
    assign: 'Assign',
    menuItemUnassign: 'Unassign',
    menuItemReAssign: 'Re-assign',
    assignSuccessful: 'Shift has been assigned successfully',
    unassignSuccessful: 'Shift has been unassigned successfully',
    unassignDialog: {
      title: 'Unassign shift',
      subtitle: 'Please, specify the reason',
      reasonLabel: 'Reason of unassigning',
      reasonPlaceholder: 'Select the reason',
      shiftDetails: '{period}, {length} {type}, {qualifications}, {assignee}',
      reasonCommentLabel: 'What is the issue?',
      reasonCommentPlaceholder: 'Please, specify the reason',
    },
    assignDialog: {
      title: 'Assign shift',
      placeholder: 'Please, specify the provider',
      shiftDetails: '{period}, {length} {type}, {qualifications}, {assignee}',
    },
    startDateAll: 'All',
    shiftDetails: 'Shift details',
    locationDetails: 'Location details',
    mainScheduler: 'Main scheduler',
    afterHoursScheduler: 'After-hours scheduler',
    generalInfo: 'General info',
    notes: 'Notes',
    billing: 'Billing',
    dayOneInstructions: 'Day one instructions',
    additionalNotes: 'Additional notes',
    historyLog: 'History log',
    logs: {
      created: 'Shift created',
      shared: 'Shift shared',
      assigned: 'Shift assigned:',
      started: 'Shift started:',
      shiftTime: '{date}, {timeRange}, {shiftLength}h {shiftType}',
    },
  },
  billing: {
    bonusRate: 'Bonus rate',
    rate: {
      perHour: 'Per hour',
      perShift: 'Per shift',
    },
    rates: 'Rates',
    totals: 'Totals',
    billRate: 'Bill rate',
    payRate: 'Pay rate',
    bonusAmount: 'Bonus amount',
    billAmount: 'Bill amount',
    payAmount: 'Pay amount',
    grossMargin: 'Gross margin',
    totalTime: 'Total time',
  },
  filters: {
    dateRange: {
      previousQuarter: 'Previous quarter',
      previousWeek: 'Previous week',
      today: 'Today',
      thisWeek: 'This week',
      thisQuarter: 'This quarter',
      nextWeek: 'Next week',
      nextQuarter: 'Next quarter',
    },
    sharedWithin: {
      menuLabel: 'Shared within',
      last24Hours: 'Last 24 hours',
      last48Hours: 'Last 48 hours',
      allTime: 'All time',
      last7Days: 'Last 7 days',
      last30Days: 'Last 30 days',
    },
  },
  talents: {
    addButtonLabel: 'Add provider',
    addMenu: {
      addSingle: 'Individual provider',
      csvImport: 'CSV import',
    },
    qualifications: 'Qualifications',
    lastWorked: 'Last worked',
    statuses: {
      active: 'Active',
      deactivated: 'Deactivated',
      deleted: 'Deleted',
      invitefailed: 'Invite failed',
      invited: 'Invited',
      pending: 'Pending',
    },
    qualification: 'Qualification',
    specialty: 'Specialty',
    editForm: {
      header: 'Add internal provider',
      personalInformation: 'Personal Information',
      profileImageTitle: 'Upload profile image',
      profileImageDisclaimer: 'Image should be PNG or JPG format, 2MB max.',
      profileImageSubmitTitle: 'Upload image',
      contactInformation: 'Contact Information',
      hrInformation: 'HR Information',
      primaryRegion: 'Primary region',
      secondaryRegion: 'Secondary region',
      roleAndPosition: 'Role & Position',
      qualificationAndSpecialties: 'Qualification and specialties',
      specialtiesApplied: 'Specialties applied',
      rateType: 'Rate type',
      baseRate: 'Base rate',
      deleteQualification: 'Delete qualification',
      addQualification: 'Add qualification',
      deleteSpecialty: 'Delete specialty',
      addSpecialty: 'Add specialty',
      travel: 'Travel',
      availableForTravel: 'Available for travel',
      schedule: 'Schedule',
      success: 'Provider has been created successfully',
      maxTravelDistance: 'Max. travel distance (in miles)',
    },
    rateTypes: {
      hourly: 'Hourly',
      monthly: 'Monthly',
      yearly: 'Yearly',
    },
    table: {
      noRows: {
        title: 'No providers yet',
        subtitle: 'You don’t have any providers yet',
      },
    },
    deactivateUser: 'Deactivate User',
    activateUser: 'Activate User',
    deactivateUsers: 'Deactivate user(s)',
    sendInvites: 'Send invite(s)',
    resendInvites: 'Resend invite(s)',
    staffingType: {
      label: 'Staffing type',
      internal: 'Internal',
      agency: 'Agency',
      directContractor: 'Direct contractor',
    },
    employmentType: {
      label: 'Employment type',
      fullTime: 'Full time',
      partTime: 'Part time',
    },
    fields: {
      specialities: 'Specialities',
      nurseQualifications: 'Qualifications',
    },
    deactivateSuccess: 'Provider has been deactivated successfully',
    activateSuccess: 'Provider has been activated successfully',
  },
  clients: {
    table: {
      noRows: {
        title: 'No clients yet',
        subtitle: 'You don’t have any client’s requests yet',
      },
      noLocations: {
        title: 'No locations yet',
        subtitle: 'No locations yet',
      },
    },
    viewSwitcher: {
      clients: 'Clients',
      locations: 'Locations',
    },
    locationsNumber: '{number, plural, =0 {-} one {# location} other {# locations} }',
    contract: {
      label: 'Contract',
      direct: 'Direct',
      managed: 'Managed',
    },
    regions: 'Regions',
    openShifts: 'Open shifts',
    lastWorked: 'Last worked',
    completedShifts: 'Completed shifts',
    inviteStatus: {
      label: 'Invite status',
      active: 'Active',
      disconnected: 'Disconnected',
      pending: 'Pending',
    },
    manager: 'Manager',
    assignManager: 'Assign manager',
  },
  users: {
    status: {
      InviteFailed: 'Invite failed',
      Invited: 'Invited',
      Pending: 'Pending',
      Active: 'Active',
      Deactivated: 'Deactivated',
    },
    usersCount: '{number, plural, =0 {No users} one {# user} other {# users} }',
    table: {
      noRows: {
        title: 'No users yet',
        subtitle: 'You don’t have any users yet added',
      },
    },
  },
});
