import { Flex } from '@atoms/layout';
import { styled, Typography } from '@mui/material';

type ProgressProps = {
  radius?: number;
  strokeWidth?: number;
  progress?: number;
};
export const CircularProgress = ({ radius = 14, strokeWidth = 3, progress = 0 }: ProgressProps) => {
  const normalizedProgress = Math.min(Math.max(progress, 0), 100);
  const angle = (normalizedProgress / 100) * 360;
  const r = radius + strokeWidth / 2;
  const start = -90;
  const angleInternal = start + angle;
  const startX = radius * Math.cos((start * Math.PI) / 180);
  const startY = radius * Math.sin((start * Math.PI) / 180);
  const x = radius * Math.cos((angleInternal * Math.PI) / 180);
  const y = radius * Math.sin((angleInternal * Math.PI) / 180);
  const largeArcFlag = angle > 180 ? 1 : 0;
  const fullCircle = <circle cx="0" cy="0" r={radius} stroke="#0F8813" strokeWidth={strokeWidth} />;
  const arc = (
    <path
      strokeLinecap="round"
      d={`M ${startX} ${startY} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${x} ${y}`}
      fill="none"
      stroke="#0F8813"
      strokeWidth={strokeWidth}
    />
  );

  return (
    <Flex justifyCenter alignCenter position="relative">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={r * 2}
        height={r * 2}
        viewBox={`${-r} ${-r} ${r * 2} ${r * 2}`}
        fill="none"
      >
        <circle cx="0" cy="0" r={radius} stroke="black" strokeOpacity="0.06" strokeWidth={strokeWidth} />
        {angle === 360 ? fullCircle : angle > 0 ? arc : null}
      </svg>
      <PercentageContainer alignCenter justifyCenter>
        <Flex alignItems="baseline">
          <PercentageText fontWeight={600}>{normalizedProgress}</PercentageText>
          <PercentageSymbol>%</PercentageSymbol>
        </Flex>
      </PercentageContainer>
    </Flex>
  );
};

const PercentageContainer = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const PercentageText = styled(Typography)`
  font-size: 10px;
`;

const PercentageSymbol = styled(Typography)`
  font-size: 6px;
`;
