import { useTheme } from '@mui/material';
import { FaIcon, IconName, type IconProps } from './faIcon';

export type DuotoneIconProps = IconProps & {
  active?: boolean;
  colorActive?: string;
};
export const DuotoneIcon = ({ active, color, colorActive, ...props }: DuotoneIconProps) => {
  const theme = useTheme();
  const variant = active ? 'duotone' : 'solid';
  const internalColor = active ? (colorActive ?? theme.palette.primary.main) : (color ?? theme.palette.text.secondary);

  return <FaIcon color={color ?? internalColor} variant={variant} {...props} />;
};
