import {
  PatternFormat as ReactPatternFormat,
  PatternFormatProps as ReactPatternFormatProps,
} from 'react-number-format';
import { TextField, TextFieldProps } from './textField';
import { forwardRef } from 'react';

export type PatternFormatProps = TextFieldProps & {
  defaultValue?: ReactPatternFormatProps['defaultValue'];
  value?: ReactPatternFormatProps['value'];
  type?: ReactPatternFormatProps['type'];
  format: ReactPatternFormatProps['format'];
};
export const PatternFormat = forwardRef<HTMLInputElement, PatternFormatProps>(function PatternFormat(props, ref) {
  return <ReactPatternFormat customInput={TextField} {...props} inputRef={ref} />;
});
