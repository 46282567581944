import MenuItem from '@mui/material/MenuItem';
import { OptionProps } from './types';

export function renderHighlightedOption({ option }: OptionProps) {
  return (
    <MenuItem key={option.value} value={option.value}>
      {option.label}
    </MenuItem>
  );
}
