import { create } from 'zustand';
import { adminRegister, RegisterProps } from '@libs/api/auth';

type State = {
  adminSignUp: (data: RegisterProps) => Promise<void>;
  loading: boolean;
};

export const useSignUpStore = create<State>()((set) => ({
  loading: false,
  adminSignUp: async (data) => {
    set({
      loading: true,
    });
    try {
      await adminRegister(data);
    } finally {
      set({
        loading: false,
      });
    }
  },
}));
