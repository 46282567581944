import { ADDRESS_SEARCH_COUNTRIES, AZURE_KEY } from '@libs/config';
import MapsSearch, { LatLongPairAbbreviatedOutput, SearchAddressResultOutput } from '@azure-rest/maps-search';
import { AzureKeyCredential } from '@azure/core-auth';
export { type SearchAddressResultOutput } from '@azure-rest/maps-search';

import { getClient } from '@azure-rest/core-client';

const credential = new AzureKeyCredential(AZURE_KEY);
const client = MapsSearch(credential);

export const getAddressValue = async (address: string) => {
  const response = await client.path('/search/address/{format}', 'json').get({
    queryParameters: {
      'api-version': '1.0',
      query: address,
      countrySet: ADDRESS_SEARCH_COUNTRIES,
    },
  });
  if (response.status !== '200') {
    throw new Error('Failed to get address');
  }

  // Need to cast type due to pure AZURE MAPS API typings
  return response.body as SearchAddressResultOutput;
};

type Coords = { lat: number; lon: number } | number[] | LatLongPairAbbreviatedOutput;

export const getTimezone = async (coordinates: Coords) => {
  //timezone/byCoordinates/json

  const coord =
    !Array.isArray(coordinates) && coordinates.lat
      ? [coordinates.lat, coordinates.lon]
      : Array.from(coordinates as number[]);
  const response = await getClient(`https://atlas.microsoft.com`, credential)
    .pathUnchecked('/timezone/byCoordinates/json')
    .get({
      queryParameters: {
        'api-version': '1.0',
        query: coord,
        options: 'zoneInfo',
        countrySet: ADDRESS_SEARCH_COUNTRIES,
      },
    });
  return response;
};
