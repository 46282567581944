import { ReactNode } from 'react';
import { noop } from '@libs/utils/empty';
import { alpha, styled } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import { buttonBaseClasses } from '@mui/material/ButtonBase';
import { listItemClasses } from '@mui/material';

type Icon = ReactNode | ((props: { active: boolean }) => ReactNode);
type MenuButtonProps = {
  active: boolean;
  label: ReactNode;
  icon: Icon;
  onClick?: () => void;
};
export const SidebarButton = ({ icon, label, active, onClick = noop }: MenuButtonProps) => {
  const iconElement = typeof icon === 'function' ? icon({ active }) : icon;
  return (
    <SidebarItem selected={active} onClick={onClick}>
      <Icon>{iconElement}</Icon>
      <Typography variant={active ? 'subtitle2' : 'body2'} lineHeight="20px">
        {label}
      </Typography>
    </SidebarItem>
  );
};

const SidebarItem = styled(ListItemButton)`
  border-radius: 8px;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.text.secondary};
  &:hover {
    background: ${({ theme }) => alpha(theme.palette.primary.main, 0.08)};
  }
  &.${buttonBaseClasses.focusVisible} {
    background: ${({ theme }) => alpha(theme.palette.primary.main, 0.08)};
  }
  &.${listItemClasses.selected} {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const Icon = styled(ListItemIcon)`
  color: ${({ theme }) => theme.palette.text.secondary};
  width: 24px;
  height: 24px;
  min-width: 0;
  display: flex;
  justify-content: center;
`;
