import { forwardRef, ReactNode, useId, useRef } from 'react';
import Select, { SelectProps } from '@mui/material/Select';
import { FormHelperText } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import { Box, Flex } from '@atoms/layout';
import { renderHighlightedOption } from './selectOptions/renderHighlightedOption';
import { OptionProps } from './selectOptions/types';
import { FormLabel } from './formLabel';

export type MultiSelectProps = Omit<SelectProps<string[]>, 'value' | 'onChange'> & {
  options: { value: string; label: string }[];
  value: string[];
  renderOption?: (props: OptionProps) => JSX.Element;
  onChange?: (value: string[], event: Parameters<Exclude<SelectProps['onChange'], undefined>>[0]) => void;
  controls?: ReactNode;
  helperText?: React.ReactNode;
};
export const MultiSelect = forwardRef<HTMLDivElement, MultiSelectProps>(function MultiSelect(
  {
    options,
    id,
    labelId,
    renderOption = renderHighlightedOption,
    onChange,
    controls,
    helperText,
    placeholder,
    ...props
  },
  ref,
) {
  const theme = useTheme();
  const { disabled, required, label, fullWidth } = props;
  const generatedId = useId();
  const generatedLabelId = useId();
  const internalId = id ?? generatedId;
  const internalLabelId = labelId ?? generatedLabelId;

  return (
    <FormControl fullWidth={fullWidth}>
      {label && (
        <label id={internalLabelId} htmlFor={internalId}>
          <FormLabel disabled={disabled} required={required}>
            {label}
          </FormLabel>
        </label>
      )}
      <Select
        variant="outlined"
        labelId={internalLabelId}
        id={internalId}
        multiple
        inputRef={ref}
        input={<OutlinedInput />}
        // To be able to call `renderValue` when no options are selected
        displayEmpty
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <Box sx={{ opacity: 0.42 }}>{placeholder}</Box>;
          }
          return selected
            .map((v) => options.find((o) => o.value === v)?.label)
            .filter(Boolean)
            .join(', ');
        }}
        onChange={(e) => {
          const newValue = e.target.value as string[];
          e.target.value = newValue;
          onChange?.(newValue, e);
        }}
        MenuProps={{
          sx: {
            '& .MuiMenu-list': {
              paddingBottom: controls ? 0 : undefined,
            },
          },
        }}
        {...props}
      >
        {options.map((o) => renderOption({ option: o, active: props.value.includes(o.value) }))}
        {!!controls && (
          <Flex bgcolor={theme.palette.background.paper} position="sticky" bottom={0} px={2} pb={2} pt={1}>
            {controls}
          </Flex>
        )}
      </Select>
      <FormHelperText error={props.error}>{helperText}</FormHelperText>
    </FormControl>
  );
});
