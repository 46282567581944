import { Typography } from '@mui/material';
import { DuotoneIcon } from '@atoms/icons';
import { Box, Flex } from '@atoms/layout';

type ErrorMessageProps = {
  error?: Error;
};
export function ErrorMessage({ error }: ErrorMessageProps) {
  return (
    <>
      <Flex alignCenter justifyCenter height="100%" column textAlign="center">
        <Box fontSize="80px" mb={3}>
          <DuotoneIcon name="bulldozer" active />
        </Box>
        <Box mt={1.5}>
          <Typography width="344px" color="error">
            {error?.message}
          </Typography>
        </Box>
      </Flex>
    </>
  );
}
