import * as amplitude from '@amplitude/analytics-browser';
import { Analytics as LibAnalytics } from '@shiftnex/framework/src/libs/analytics';

export class Analytics extends LibAnalytics {
  static async track(
    eventInput: string,
    eventProperties?: Record<string, string | number | boolean>,
    eventOptions?: amplitude.Types.EventOptions,
  ) {
    return await super.track(
      eventInput,
      {
        ProductID: 'AX',
        ...eventProperties,
      },
      eventOptions,
    );
  }
}
