import dayjs from 'dayjs';
import { DateRangeShortcutsItem } from '@libs/models/common';

export const dateRangeShortcuts: DateRangeShortcutsItem[] = [
  {
    labelKey: 'filters.dateRange.previousQuarter',
    getValue: () => {
      const today = dayjs();
      const startOfPrevQuarter = today.subtract(1, 'quarter').startOf('quarter');
      return [startOfPrevQuarter, startOfPrevQuarter.endOf('quarter')];
    },
  },
  {
    labelKey: 'filters.dateRange.previousWeek',
    getValue: () => {
      const today = dayjs();
      const startOfPrev = today.subtract(1, 'week').startOf('week');
      return [startOfPrev, startOfPrev.endOf('week')];
    },
  },
  {
    labelKey: 'filters.dateRange.today',
    getValue: () => {
      const today = dayjs().startOf('day');
      return [today, today.endOf('day')];
    },
  },
  {
    labelKey: 'filters.dateRange.thisWeek',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('week'), today.endOf('week')];
    },
  },
  {
    labelKey: 'filters.dateRange.thisQuarter',
    getValue: () => {
      const today = dayjs();
      const startOfPrevQuarter = today.startOf('quarter');
      return [startOfPrevQuarter, startOfPrevQuarter.endOf('quarter')];
    },
  },
  {
    labelKey: 'filters.dateRange.nextWeek',
    getValue: () => {
      const today = dayjs();
      const startOfPrev = today.add(1, 'week').startOf('week');
      return [startOfPrev, startOfPrev.endOf('week')];
    },
  },
  {
    labelKey: 'filters.dateRange.nextQuarter',
    getValue: () => {
      const today = dayjs();
      const startOfPrevQuarter = today.add(1, 'quarter').startOf('quarter');
      return [startOfPrevQuarter, startOfPrevQuarter.endOf('quarter')];
    },
  },
  { labelKey: 'common.all', getValue: () => [null, null] },
];
