import { PropsWithChildren } from 'react';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { IntlProvider } from './libs/intl';
import { ThemeProvider } from './theme';

export function Providers({ children }: PropsWithChildren) {
  return (
    <IntlProvider>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <SnackbarProvider />
          {children}
        </LocalizationProvider>
      </ThemeProvider>
    </IntlProvider>
  );
}
