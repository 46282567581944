import { PropsWithChildren } from 'react';
import { useTheme } from '@mui/material';
import MuiIconButton, { IconButtonProps } from '@mui/material/IconButton';

export interface ContainedIconButtonProps extends IconButtonProps {}

export const ContainedIconButton = ({ children, sx, ...props }: PropsWithChildren<ContainedIconButtonProps>) => {
  const theme = useTheme();

  return (
    <MuiIconButton
      sx={{
        backgroundColor: theme.palette.common.white,
        aspectRatio: '1 / 1',
        ...sx,
      }}
      {...props}
    >
      {children}
    </MuiIconButton>
  );
};
