import { Skeleton, styled, Box, BoxProps } from '@mui/material';

type DelayedLinearProgressProps = BoxProps & {
  show?: boolean;
};
export function SkeletonProgress({ show = true, children, ...props }: DelayedLinearProgressProps) {
  return (
    <>
      {show ? (
        <StyledBox {...props}>
          <Box sx={{ width: '100px' }}>
            <Skeleton animation="pulse" />
            <Skeleton animation="wave" />
            <Skeleton animation="pulse" />
          </Box>
        </StyledBox>
      ) : (
        children
      )}
    </>
  );
}

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
