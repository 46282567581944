import { request } from '../request';

export function submitOtp(email: string, otp: string) {
  return request.post<{
    token: string;
    expireTime: string;
  }>('/v2/identity/login-otp', {
    email,
    OTP: otp,
  });
}
