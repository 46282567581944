import { request } from '../request';

export function adminRegister(data: RegisterProps) {
  return request.post('/v2/identity/register', data);
}

export type RegisterProps = {
  firstName: string;
  lastName: string;
  email: string;
};
