import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { SingleInputDateRangeFieldProps } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import { DateRange as MuiDateRange } from '@mui/x-date-pickers-pro/models';
import { styled, useTheme, useThemeProps } from '@mui/material/styles';
import { useForkRef } from '@mui/material/utils';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { DateRangePicker, DateRangePickerProps } from '@mui/x-date-pickers-pro/DateRangePicker';
import { Box, Flex } from '@atoms/layout';
import { FaIcon } from '@atoms/icons';

export type DateRangeSelectorProps = Omit<DateRangePickerProps<Dayjs>, 'open' | 'onOpen' | 'onClose'> & {
  fullWidth?: boolean;
  variant?: 'outlined';
  shortcutsItems?: ShortcutsItem[];
};
export const DateRangeSelector = React.forwardRef(function DateRangeSelector(
  inProps: DateRangeSelectorProps,
  ref: React.Ref<HTMLDivElement>,
) {
  const [open, setOpen] = React.useState(false);
  const props = useThemeProps({ props: inProps, name: 'DateRangeSelector' });
  const { fullWidth, shortcutsItems } = props;
  const fieldProps: DateRangeButtonFieldProps = {
    setOpen,
    fullWidth,
    ownerState: props,
  };
  const theme = useTheme();

  return (
    <DateRangePicker
      slots={{ field: DateRangeButtonField, ...props.slots }}
      slotProps={{
        // There is no possibility to override the slotProps types when using custom slots
        // Example: https://mui.com/x/react-date-pickers/custom-field/#using-a-button
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
        field: fieldProps as any,
        shortcuts: {
          items: shortcutsItems,
          sx: {
            bgcolor: `color-mix(in srgb, #fff 100%, ${theme.palette.primary.main} 8%)`,
          },
        },
      }}
      ref={ref}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  );
});

interface DateRangeButtonFieldProps extends SingleInputDateRangeFieldProps<Dayjs> {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  ownerState: DateRangeSelectorProps;
}

const DateRangeButtonField = React.forwardRef(function DateRangeButtonField(
  props: DateRangeButtonFieldProps,
  ref: React.Ref<HTMLElement>,
) {
  const {
    setOpen,
    label,
    id,
    fullWidth,
    InputProps: { ref: containerRef } = {},
    inputProps: { 'aria-label': ariaLabel } = {},
    ownerState,
  } = props;
  const theme = useTheme();
  const [from, to] = ownerState.value || [];
  const defaultLabel =
    [from?.format('MMM D, YYYY'), to?.format('MMM D, YYYY')].filter(Boolean).join(' - ') || 'Select Date Range';

  const handleRef = useForkRef(ref, containerRef);

  return (
    <DateRangeSelectorButton
      id={id}
      ref={handleRef}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}
      fullWidth={fullWidth}
      ownerState={ownerState}
    >
      <Flex justifyContent="space-between" width="100%">
        <Box minWidth={0} width="100%">
          {label ?? (
            <Typography variant="body1" color={theme.palette.text.secondary} noWrap>
              {defaultLabel}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            position: 'absolute',
            right: '7px',
            top: '3px',
          }}
        >
          <IconButton size="small">
            <FaIcon name="calendar" />
          </IconButton>
        </Box>
      </Flex>
    </DateRangeSelectorButton>
  );
});

// @ts-expect-error fieldType should be set for attaching calendar dialog to the button
DateRangeButtonField.fieldType = 'single-input';

const DateRangeSelectorButton = styled('div', {
  name: 'DateRangeSelector',
  slot: 'button',
  shouldForwardProp: (prop: string) => !['fullWidth', 'ownerState'].includes(prop),
})<{ ownerState: StatOwnerState; fullWidth?: boolean }>(({ theme, fullWidth }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.background.paper}`,
  [`&:hover`]: {
    border: `1px solid ${theme.palette.text.primary}`,
  },
  borderRadius: '100px',
  width: fullWidth ? '100%' : 'auto',
  height: '40px',
  justifyContent: 'start',
  padding: `${theme.spacing(1)} ${theme.spacing(1.75)}`,
  paddingRight: theme.spacing(4),
  display: 'flex',
  position: 'relative',
}));

interface StatOwnerState extends DateRangeSelectorProps {
  // …key value pairs for the internal state that you want to style the slot
  // but don't want to expose to the users
}

export type ShortcutsItem = PickersShortcutsItem<MuiDateRange<Dayjs>>;

export type DateRange = MuiDateRange<dayjs.Dayjs>;
