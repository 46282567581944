import { create } from 'zustand';
import { DateRangeShortcutsItem } from '@libs/models/common';
import { dateRangeShortcuts } from '@libs/models/shifts';
import { createDefaultFilterSlice, DefaultFilterState } from '../defaultFilterSlice';

type ShiftFilterState = {
  dateRangeShortcuts: DateRangeShortcutsItem[];
};
export const useShiftsFilter = create<DefaultFilterState & ShiftFilterState>()((...a) => ({
  ...createDefaultFilterSlice(...a),
  dateRangeShortcuts: dateRangeShortcuts,
}));
